import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import axiosInstance from "../../components/axiosInstance";

// ** Fetch Invoices
export const fetchMonths = createAsyncThunk('get/months', async (_,{ rejectWithValue }) => {
  try {
    let params = {table_name: 'months'};
    const response = await axiosInstance.get('form-components', { params: params });
    return response.data.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const { message, errors } = error.response.data;
      const errorObj = { message, errors };
      return rejectWithValue(errorObj);
    } else {
      if (error.response && error.response.status === 403) {
        return rejectWithValue(error.response.status);
      }
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
  } else {
    state.message = payload
  }
}

export const monthsSlice = createSlice({
  name: 'months',
  initialState: {
    data: [], // Array to store months
    message: '', // Message from API response
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchMonths.pending, (state) => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchMonths.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving months to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchMonths.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!';
      state.loading = false // Set loading false to hide loading screen
    })
  }
});

export default monthsSlice.reducer

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import {Outlet, useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

// Styled component for Blank Layout component
const BlankLayoutWrapper = styled(Box)(({ theme }) => ({
  height: '100vh',

  // For V1 Blank layout pages
  '& .content-center': {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5)
  },

  // For V2 Blank layout pages
  '& .content-right': {
    display: 'flex',
    minHeight: '100vh',
    overflowX: 'hidden',
    position: 'relative'
  }
}))

const BlankLayout = ({guestRoute}) => {
  const auth = useAuth()
  const navigate = useNavigate()
  console.log(auth)
  if(auth.user && guestRoute) {
    navigate('/')
  }
  return (
    <BlankLayoutWrapper className='layout-wrapper'>
      <Box className='app-content' sx={{ overflow: 'hidden', minHeight: '100vh', position: 'relative' }}>
        <Outlet />
      </Box>
    </BlankLayoutWrapper>
  )
}

export default BlankLayout

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axiosInstance from "../../components/axiosInstance";

// ** Fetch Data
export const fetchServiceTypesInReports = createAsyncThunk('get/serviceTypesInReports', async (_, { rejectWithValue }) => {
  try {
    let params = { table_name: 'service_type' };
    const response = await axiosInstance.get('get-dropdown', { params: params });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const { message, errors } = error.response.data;
      const errorObj = { message, errors };
      return rejectWithValue(errorObj);
    } else {
      if (error.response && error.response.status === 403) {
        return rejectWithValue(error.response.status);
      }
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload.data) return

  if (payload.data) {
    state.data = payload.data
  } else {
    state.message = payload
  }
}

export const serviceTypeInReportsSlice = createSlice({
  name: 'service_type_in_reports',
  initialState: {
    data: [], // Array to store data
    message: '', // Message from API response
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchServiceTypesInReports.pending, (state) => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchServiceTypesInReports.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving data to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchServiceTypesInReports.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!';
      state.loading = false // Set loading false to hide loading screen
    })
  }
});

export default serviceTypeInReportsSlice.reducer

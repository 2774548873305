import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/components/axiosInstance'
import createDataSlice from 'src/store/components/createDataSlice'
import handleError from 'src/store/components/handleError'
import saveToRedux from 'src/store/components/saveToRedux'

const modelName = 'DailyLog'
const tableName = 'daily_logs'
const pathName = 'daily-logs'

// **Find Specific
export const findRecords = createAsyncThunk(`${modelName}s/find${modelName}s`, async (id, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${pathName}/${id}`)
    return response.data
  } catch (error) {
    return rejectWithValue(handleError(error))
  }
})

const initialState = {
  data: [], // Array to store Referral Point
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}

const extraReducers = builder => {
  // ** FIND CASES
  builder.addCase(findRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })
  builder.addCase(findRecords.fulfilled, (state, action) => {
    saveToRedux(state, action.payload)
    state.loading = false // Set loading false to hide loading screen
  })
  builder.addCase(findRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to find ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })
}
export const dataSlice = createDataSlice(`${tableName}-details`, extraReducers, initialState)
export default dataSlice.reducer

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axiosInstance from '../../components/axiosInstance'

// ** Fetch Invoices
export const fetchYears = createAsyncThunk('get/years', async (params, { rejectWithValue }) => {
  try {
    params.table_name = 'years'
    const response = await axiosInstance.get('form-components', { params: params })
    return response.data.data
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const { message, errors } = error.response.data
      const errorObj = { message, errors }
      return rejectWithValue(errorObj)
    } else {
      if (error.response && error.response.status === 403) {
        return rejectWithValue(error.response.status)
      }
      return rejectWithValue(error.message || 'An error occurred')
    }
  }
})
const saveToRedux = (state, payload) => {
  if (state.data === payload?.data) return

  if (payload?.data) {
    console.log(payload.data.length)
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

export const yearSlice = createSlice({
  name: 'years',
  initialState: {
    data: [], // Array to store Referral Point
    message: '', // Message from API response
    nextPageUrl: null,
    prevPageUrl: null,
    total: 0,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    // API fetching state
    builder.addCase(fetchYears.pending, state => {
      state.loading = true // Set loading true to show loading screen
    })

    // API response received state (SUCCESS)
    builder.addCase(fetchYears.fulfilled, (state, action) => {
      // Assuming action.payload is the response from the API
      saveToRedux(state, action.payload) // Saving years to redux
      state.loading = false // Set loading false to hide loading screen
    })

    // API response received state (FAILURE)
    builder.addCase(fetchYears.rejected, (state, action) => {
      state.message = action.payload?.message || 'Failed to fetch data!'
      state.loading = false // Set loading false to hide loading screen
    })
  }
})

export default yearSlice.reducer

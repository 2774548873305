import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "src/store/components/axiosInstance";
import handleError from "src/store/components/handleError";
import createDataSlice from "src/store/components/createDataSlice";
import saveToRedux from "src/store/components/saveToRedux";

import {pathName, tableName, modelName} from "./aggregate";

// ** Fetch Detail Records
export const fetchRecords = createAsyncThunk(`${modelName}s/fetch${modelName}s`, async (params, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${pathName}/detail`, params)
    return response.data.data
  } catch (error) {
    handleError(error)
  }
})

// ** Delete Record
export const deleteRecord = createAsyncThunk(`${modelName}s/delete${modelName}`, async (id, { getState, dispatch }) => {
  try {
    await axiosInstance.delete(`${pathName}/${id}`)
    dispatch(fetchAggregateRecords(getState().centers?.params))
    return id;
  } catch (error) {
    handleError(error, 500)
  }
})

const initialState = {
  data: [], // Array to store Referral Point
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}

const extraReducers = builder => {
  // *** DETAIL FETCH CASES
  // API fetching state
  builder.addCase(fetchRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(fetchRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving Referral Points to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(fetchRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to add ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** DELETE CASES
  // API fetching state
  builder.addCase(deleteRecord.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(deleteRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    state.loading = false // Set loading false to hide loading screen
    state.roles = state.centers?.filter(data => data.id !== action.payload)
  })

  // API response received state (FAILURE)
  builder.addCase(deleteRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to delete ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })
}

export const dataSlice = createDataSlice(`${tableName}-detail`, extraReducers, initialState)
export default dataSlice.reducer

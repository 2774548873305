import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/components/axiosInstance'
import createDataSlice from 'src/store/components/createDataSlice'
import handleError from 'src/store/components/handleError'
import saveToRedux from 'src/store/components/saveToRedux'

const modelName = 'ReferralPointService'
const tableName = 'referral_point_services'
const pathName = 'referral-point-services'

// ** Add Record
export const fetchDetailRecords = createAsyncThunk(
  `${modelName}s/add${modelName}`,
  async (data, { getState, dispatch }) => {
    try {
      const response = await axiosInstance.get(`${pathName}/detail`, data)
      return response.data.data
    } catch (error) {
      handleError(error)
    }
  }
)

export const deleteRecord = createAsyncThunk(`${modelName}s/delete${modelName}`, async (id, { getState, dispatch }) => {
  try {
    await axiosInstance.delete(`${pathName}/${id}`)
    dispatch(fetchAggregateRecords(getState().centers?.params))
    return id
  } catch (error) {
    handleError(error, 500)
  }
})

const initialState = {
  data: [], // Array to store Referral Point
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}

const extraReducers = builder => {
  // *** DETAIL FETCH CASES
  // API fetching state
  builder.addCase(fetchDetailRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(fetchDetailRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving Referral Points to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(fetchDetailRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to add ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })
}

export const dataSlice = createDataSlice(`${tableName}-detail`, extraReducers, initialState)
export default dataSlice.reducer

const Badge = {
  MuiBadge: {
    styleOverrides: {
      badge: {
        fontSize: '0.75rem'
      }
    }
  }
}

export default Badge

// // ** Redux Imports
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// // ** Axios Imports
// import axios from 'axios'

// // ** Mock Adapter
// import mock from 'src/@fake-db/mock'

// const date = new Date()
// const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// const nextMonth =
//   date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)

// const prevMonth =
//   date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

// const data = {
//   events: [
//     {
//       id: 1,
//       url: '',
//       title: 'Design Review',
//       start: date,
//       end: nextDay,
//       allDay: false,
//       extendedProps: {
//         calendar: 'Business'
//       }
//     },
//     {
//       id: 2,
//       url: '',
//       title: 'Meeting With Client',
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
//       allDay: true,
//       extendedProps: {
//         calendar: 'Business'
//       }
//     },
//     {
//       id: 3,
//       url: '',
//       title: 'Family Trip',
//       allDay: true,
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
//       extendedProps: {
//         calendar: 'Holiday'
//       }
//     },
//     {
//       id: 4,
//       url: '',
//       title: "Doctor's Appointment",
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
//       allDay: true,
//       extendedProps: {
//         calendar: 'Personal'
//       }
//     },
//     {
//       id: 5,
//       url: '',
//       title: 'Dart Game?',
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
//       allDay: true,
//       extendedProps: {
//         calendar: 'ETC'
//       }
//     },
//     {
//       id: 6,
//       url: '',
//       title: 'Meditation',
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
//       allDay: true,
//       extendedProps: {
//         calendar: 'Personal'
//       }
//     },
//     {
//       id: 7,
//       url: '',
//       title: 'Dinner',
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
//       allDay: true,
//       extendedProps: {
//         calendar: 'Family'
//       }
//     },
//     {
//       id: 8,
//       url: '',
//       title: 'Product Review',
//       start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
//       end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
//       allDay: true,
//       extendedProps: {
//         calendar: 'Business'
//       }
//     },
//     {
//       id: 9,
//       url: '',
//       title: 'Monthly Meeting',
//       start: nextMonth,
//       end: nextMonth,
//       allDay: true,
//       extendedProps: {
//         calendar: 'Business'
//       }
//     },
//     {
//       id: 10,
//       url: '',
//       title: 'Monthly Checkup',
//       start: prevMonth,
//       end: prevMonth,
//       allDay: true,
//       extendedProps: {
//         calendar: 'Personal'
//       }
//     }
//   ]
// }

// // ------------------------------------------------
// // GET: Return calendar events
// // ------------------------------------------------
// mock.onGet('/apps/calendar/events').reply(config => {
//   // Get requested calendars as Array
//   const { calendars } = config.params

//   return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))]
// })

// // ------------------------------------------------
// // POST: Add new event
// // ------------------------------------------------
// mock.onPost('/apps/calendar/add-event').reply(config => {
//   // Get event from post data
//   const { event } = JSON.parse(config.data).data
//   const { length } = data.events
//   let lastIndex = 0
//   if (length) {
//     lastIndex = data.events[length - 1].id
//   }
//   event.id = lastIndex + 1
//   data.events.push(event)

//   return [201, { event }]
// })

// // ------------------------------------------------
// // POST: Update Event
// // ------------------------------------------------
// mock.onPost('/apps/calendar/update-event').reply(config => {
//   const eventData = JSON.parse(config.data).data.event

//   // Convert Id to number
//   eventData.id = Number(eventData.id)
//   const event = data.events.find(ev => ev.id === Number(eventData.id))
//   if (event) {
//     Object.assign(event, eventData)

//     return [200, { event }]
//   } else {
//     return [400, { error: `Event doesn't exist` }]
//   }
// })

// // ------------------------------------------------
// // DELETE: Remove Event
// // ------------------------------------------------
// mock.onDelete('/apps/calendar/remove-event').reply(config => {
//   // Get event id from URL
//   const { id } = config.params

//   // Convert Id to number
//   const eventId = Number(id)
//   const eventIndex = data.events.findIndex(ev => ev.id === eventId)
//   data.events.splice(eventIndex, 1)

//   return [200]
// })

// // ** Fetch Events
// export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async calendars => {
//   const response = await axios.get('/apps/calendar/events', {
//     params: {
//       calendars
//     }
//   })

//   return response.data
// })

// // ** Add Event
// export const addEvent = createAsyncThunk('appCalendar/addEvent', async (event, { dispatch }) => {
//   const response = await axios.post('/apps/calendar/add-event', {
//     data: {
//       event
//     }
//   })
//   await dispatch(fetchEvents(['Personal', 'Business', 'Family', 'Holiday', 'ETC']))

//   return response.data.event
// })

// // ** Update Event
// export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, { dispatch }) => {
//   const response = await axios.post('/apps/calendar/update-event', {
//     data: {
//       event
//     }
//   })
//   await dispatch(fetchEvents(['Personal', 'Business', 'Family', 'Holiday', 'ETC']))

//   return response.data.event
// })

// // ** Delete Event
// export const deleteEvent = createAsyncThunk('appCalendar/deleteEvent', async (id, { dispatch }) => {
//   const response = await axios.delete('/apps/calendar/remove-event', {
//     params: { id }
//   })
//   await dispatch(fetchEvents(['Personal', 'Business', 'Family', 'Holiday', 'ETC']))

//   return response.data
// })

// export const appCalendarSlice = createSlice({
//   name: 'appCalendar',
//   initialState: {
//     events: [],
//     selectedEvent: null,
//     selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
//   },
//   reducers: {
//     handleSelectEvent: (state, action) => {
//       state.selectedEvent = action.payload
//     },
//     handleCalendarsUpdate: (state, action) => {
//       const filterIndex = state.selectedCalendars.findIndex(i => i === action.payload)
//       if (state.selectedCalendars.includes(action.payload)) {
//         state.selectedCalendars.splice(filterIndex, 1)
//       } else {
//         state.selectedCalendars.push(action.payload)
//       }
//       if (state.selectedCalendars.length === 0) {
//         state.events.length = 0
//       }
//     },
//     handleAllCalendars: (state, action) => {
//       const value = action.payload
//       if (value === true) {
//         state.selectedCalendars = ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
//       } else {
//         state.selectedCalendars = []
//       }
//     }
//   },
//   extraReducers: builder => {
//     builder.addCase(fetchEvents.fulfilled, (state, action) => {
//       state.events = action.payload
//     })
//   }
// })

// export const { handleSelectEvent, handleCalendarsUpdate, handleAllCalendars } = appCalendarSlice.actions

// export default appCalendarSlice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/components/axiosInstance'
import createDataSlice from 'src/store/components/createDataSlice'
import handleError from 'src/store/components/handleError'
import saveToRedux from 'src/store/components/saveToRedux'
import { createSlice } from '@reduxjs/toolkit'

const modelName = 'Event'
const tableName = 'events'
const pathName = 'events'

// ** Fetch Records
export const fetchRecords = createAsyncThunk(
  `${modelName}s/fetch${modelName}s`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(pathName, params)
      return response.data.data
    } catch (error) {
      handleError(error)
    }
  }
)

// ** Add Record
export const addRecord = createAsyncThunk(`${modelName}s/add${modelName}`, async (data, { getState, dispatch }) => {
  try {
    const response = await axiosInstance.post(`${pathName}`, data)
    dispatch(fetchRecords(getState().centers?.params))
    return response.data
  } catch (error) {
    handleError(error)
  }
})

// ** Update Record
export const updateRecord = createAsyncThunk(
  `${modelName}s/update${modelName}`,
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      console.log(data)
      const response = await axiosInstance.put(`${pathName}/${data.id}`, data)
      dispatch(fetchRecords(getState().centers?.params))
      return response.data
    } catch (error) {
      handleError(error)
    }
  }
)

// ** Delete Record
export const deleteRecord = createAsyncThunk(`${modelName}s/delete${modelName}`, async (id, { getState, dispatch }) => {
  try {
    await axiosInstance.delete(`${pathName}/${id}`)
    dispatch(fetchRecords(getState().centers?.params))
    return id
  } catch (error) {
    handleError(error, 500)
  }
})

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [],
    selectedEvent: null,
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
  },
  reducers: {
    handleSelectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.events = action.payload
    })
  }
})

export const { handleSelectEvent } = appCalendarSlice.actions

const initialState = {
  data: [], // Array to store Referral Point
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}

const extraReducers = builder => {
  // *** FETCH CASES
  // API fetching state
  builder.addCase(fetchRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(fetchRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving districts to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(fetchRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** ADD CASES
  // API fetching state
  builder.addCase(addRecord.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(addRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving Referral Points to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(addRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to add ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** UPDATE CASES
  // API fetching state
  builder.addCase(updateRecord.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(updateRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving Referral Points to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(updateRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to update ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** DELETE CASES
  // API fetching state
  builder.addCase(deleteRecord.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(deleteRecord.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    state.loading = false // Set loading false to hide loading screen
    state.roles = state.centers?.filter(data => data.id !== action.payload)
  })

  // API response received state (FAILURE)
  builder.addCase(deleteRecord.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to delete ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })
}

export const dataSlice = createDataSlice(tableName, extraReducers, initialState)
export default dataSlice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/components/axiosInstance'
import createDataSlice from 'src/store/components/createDataSlice'
import handleError from 'src/store/components/handleError'
import saveToRedux from 'src/store/components/saveToRedux'

const modelName = 'Staff'
const tableName = 'staffsDropdown'
const pathName = 'system/staffs'

export const fetchStaffsDropdown = createAsyncThunk(
  'system/staffs/fetchDropdown',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${pathName}?dropdown=true`)
      return response.data.data
    } catch (error) {
      handleError(error)
      console.log(error)
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

const initialState = {
  data: [],
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}

const extraReducers = builder => {
  // *** FETCH DROPDOWN CASES
  // API fetching state
  builder.addCase(fetchStaffsDropdown.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })
  builder.addCase(fetchStaffsDropdown.fulfilled, (state, action) => {
    state.data = action.payload
    state.loading = false // Set loading false to hide loading screen
  })
  builder.addCase(fetchStaffsDropdown.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`
    state.loading = false // Set loading false to hide loading screen
  })
}

export const dataSlice = createDataSlice(tableName, extraReducers, initialState)
export default dataSlice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/components/axiosInstance'
import createDataSlice from 'src/store/components/createDataSlice'
import handleError from 'src/store/components/handleError'
import saveToRedux from 'src/store/components/saveToRedux'

export const modelName = 'CenterService'
export const tableName = 'center_services'
export const pathName = 'center-services'

// ** Fetch Aggregate Records
export const fetchRecords = createAsyncThunk(
  `${modelName}s/fetch${modelName}s`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${pathName}/aggregate`, params)
      return response.data.data
    } catch (error) {
      handleError(error)
    }
  }
)

// ** Bulk Delete Records
export const bulkDeleteRecords = createAsyncThunk(
  `${modelName}s/bulkDelete${modelName}`,
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      const { year, month } = data.year
      await axiosInstance.delete(`${pathName}/${year}/${month}/bulkdelete`)
      dispatch(fetchRecords(getState().centers?.params))
      return year
    } catch (error) {
      handleError(error)
    }
  }
)

// ** Upload Records( IMPORT )
export const uploadRecords = createAsyncThunk(
  `${modelName}s/upload${modelName}s`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${pathName}/upload`, params)
      return response.data
    } catch (error) {
      handleError(error)
    }
  }
)

const initialState = {
  data: [], // Array to store Referral Point
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}

const extraReducers = builder => {
  // *** AGGREGATE FETCH CASES
  // API fetching state
  builder.addCase(fetchRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(fetchRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving districts to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(fetchRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** BULK DELETE CASES
  // API fetching state
  builder.addCase(bulkDeleteRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(bulkDeleteRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving Referral Points to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(bulkDeleteRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to update ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })

  // *** UPLOAD CASES
  // API fetching state
  builder.addCase(uploadRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })
  // API response received state (SUCCESS)
  builder.addCase(uploadRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving Referral Points to redux
    state.loading = false // Set loading false to hide loading screen
  })
  // API response received state (FAILURE)
  builder.addCase(uploadRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to update ${modelName}!`
    state.loading = false // Set loading false to hide loading screen
  })
}

export const dataSlice = createDataSlice(`${tableName}-aggregate`, extraReducers, initialState)
export default dataSlice.reducer

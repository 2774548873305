import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/components/axiosInstance'
import createDataSlice from 'src/store/components/createDataSlice'
import handleError from 'src/store/components/handleError'

const modelName = 'ReferralSource'
const tableName = 'referral_sources'
const pathName = 'form-components'

// ** Fetch Records
export const fetchRecords = createAsyncThunk(`app${modelName}s/fetch${modelName}s`, async params => {
  try {
    params = { ...params, table_name: tableName }
    const response = await axiosInstance.get(pathName, { params: params })
    return response.data.data
  } catch (error) {
    handleError(error)
  }
})

const saveToRedux = (state, payload) => {
  if (state.data === payload?.data) return

  if (payload?.data) {
    console.log(payload.data.length)
    state.data = payload.data
    state.total = payload.total
    state.nextPageUrl = payload.next_page_url
    state.prevPageUrl = payload.prev_page_url
  } else {
    state.message = payload
  }
}

const initialState = {
  data: [], // Array to store Referral Point
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}
const extraReducers = builder => {
  // API fetching state
  builder.addCase(fetchRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(fetchRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving districts to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(fetchRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`
    state.loading = false // Set loading false to hide loading screen
  })
}

export const dataSlice = createDataSlice(tableName, extraReducers, initialState)
export default dataSlice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/components/axiosInstance'
import createDataSlice from "src/store/components/createDataSlice";
import handleError from "src/store/components/handleError";
import saveToRedux from "src/store/components/saveToRedux";

const modelName = 'DailyLog'
const tableName = 'daily_logs'
const pathName = 'daily-logs'

// ** Fetch Records
export const fetchRecords = createAsyncThunk(`${modelName}s/fetch${modelName}s`, async (params, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(pathName+ '/summary', params)
    return response.data.data
  } catch (error) {
    handleError(error)
  }
})

const initialState = {
  data: [], // Array to store Referral Point
  message: '', // Message from API response
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
  loading: false
}

const extraReducers = builder => {
  // *** AGGREGATE FETCH CASES
  // API fetching state
  builder.addCase(fetchRecords.pending, state => {
    state.loading = true // Set loading true to show loading screen
  })

  // API response received state (SUCCESS)
  builder.addCase(fetchRecords.fulfilled, (state, action) => {
    // Assuming action.payload is the response from the API
    saveToRedux(state, action.payload) // Saving districts to redux
    state.loading = false // Set loading false to hide loading screen
  })

  // API response received state (FAILURE)
  builder.addCase(fetchRecords.rejected, (state, action) => {
    state.message = action.payload?.message || `Failed to fetch ${modelName}s!`
    state.loading = false // Set loading false to hide loading screen
  })
}
export const dataSlice = createDataSlice(`${tableName}-summary`, extraReducers, initialState)
export default dataSlice.reducer

// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'

// ** Reducers
import districts from 'src/store/apps/districts'
import centerTypes from 'src/store/apps/center-types'
import years from 'src/store/apps/years'
import activityTypes from 'src/store/apps/activity-types'
import months from 'src/store/apps/months'
import quarterly from 'src/store/apps/quarterly'
import semiAnnually from 'src/store/apps/semi-annually'
import service_type_in_reports from 'src/store/apps/service-types-in-reports'
import chart_type from 'src/store/apps/chart-type'
import sources from 'src/store/apps/sources'
import roles from 'src/store/apps/roles'
import staffs from 'src/store/apps/staffs'
import appUsers from 'src/store/apps/user'
import designations from 'src/store/apps/designations'
import appPermissions from 'src/store/apps/permissions'
import centers from 'src/store/apps/service-center'
import referral_points from 'src/store/apps/referral-point'
import dg_centers from 'src/store/apps/dgcenter-mapping'
import appIpcActivities from 'src/store/apps/ipc-activities'
import referrals from 'src/store/apps/referrals'
import referralVisits from 'src/store/apps/referral-visit'
import dailyLogsDetail from 'src/store/apps/daily-logs/detail'
import dailyLogsSummary from 'src/store/apps/daily-logs/summary'
import dailyLogsDetails from './apps/daily-logs-details'
import calendar from 'src/store/apps/calendar'
import documents from 'src/store/apps/documents'
import visit_logs from 'src/store/apps/visit-log'
import reward_incentives from 'src/store/apps/reward-incentives'
import cashRedeem from 'src/store/apps/cash-redeems'
import serviceTypes from 'src/store/apps/service-types'
import centersDropdown from 'src/store/apps/dropdown-center'
import staffsDropdown from 'src/store/apps/dropdown-staff'
import formComponentsDropdown from 'src/store/apps/form-components'
import vdc_nps from 'src/store/apps/vdcnps'
import document_types from 'src/store/apps/document-types'
import referral_points_types from 'src/store/apps/referral-point-types'
import booleans from 'src/store/apps/booleans'
import package_services from 'src/store/apps/package-services'
import complications from 'src/store/apps/complications'
import abortion_services from 'src/store/apps/abortion-services'
import before_pacs from 'src/store/apps/before-pacs'
import referral_sources from 'src/store/apps/referral-sources'
import fp_services from 'src/store/apps/fp-services'
import abortion_froms from 'src/store/apps/abortion-froms'
import transfer_statues from 'src/store/apps/transfer-statuses'
import centerServicesAggregate from 'src/store/apps/center-services/aggregate'
import centerServicesDetail from 'src/store/apps/center-services/detail'
import referralPointServicesAggregate from 'src/store/apps/referral-point-services/aggregate'
import referralPointServicesDetail from 'src/store/apps/referral-point-services/detail'
import referral_report from 'src/store/apps/referral-report'
import visitlog_report from 'src/store/apps/visit-log-report'
import ipc_event_report from 'src/store/apps/ipc-event-report'
import weekly_report from 'src/store/apps/weekly-report'
import weekno from 'src/store/apps/week-no'

export const store = configureStore({
  reducer: {
    districts,
    centerTypes,
    roles,
    staffs,
    appUsers,
    designations,
    activityTypes,
    appPermissions,
    centers,
    referral_points,
    dg_centers,
    appIpcActivities,
    referrals,
    referralVisits,
    documents,
    dailyLogsDetail,
    dailyLogsSummary,
    dailyLogsDetails,
    years,
    months,
    calendar,
    visit_logs,
    reward_incentives,
    centerServicesAggregate,
    centerServicesDetail,
    referralPointServicesAggregate,
    referralPointServicesDetail,
    cashRedeem,
    serviceTypes,
    centersDropdown,
    staffsDropdown,
    quarterly,
    semiAnnually,
    service_type_in_reports,
    referral_report,
    formComponentsDropdown,
    vdc_nps,
    document_types,
    referral_points_types,
    booleans,
    package_services,
    complications,
    abortion_services,
    before_pacs,
    referral_sources,
    fp_services,
    abortion_froms,
    transfer_statues,
    chart_type,
    sources,
    visitlog_report,
    ipc_event_report,
    weekly_report,
    weekno
},
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false
  })
})
